<template>
    <v-container>
      <v-app-bar app color="#6A76AB" dark elevate-on-scroll src="https://picsum.photos/1920/1080?random">
        <v-app-bar-nav-icon @click="drawer = !drawer" title="Menu"></v-app-bar-nav-icon>
        <v-toolbar-title class="xs-4" shrink-on-scroll>{{main_title}}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn v-if="user.loggedIn == false" class="pd-2 ml-4 xs" elevation="8" rounded outlined to="login_main" title="Login" @click="dial_reg = true">
          <v-icon class="md-2" left>mdi-account-circle</v-icon>
          {{btn_access}}
        </v-btn>

        <v-btn v-if="user.loggedIn == true" class="error pd-2 ml-4 xs" elevation="8" rounded title="Cerrar Sesion" @click.prevent="signOut">
          <v-icon class="md-2" left>mdi-exit-to-app</v-icon>
          {{btn_exit}}        
        </v-btn>
        
        <v-btn v-else class="success pd-2 ml-4 xs" elevation="8" rounded to="register_main" title="Registro" @click="dial_reg = true">
          <v-icon class="md-2" left>mdi-account-plus</v-icon>
          {{btn_create}}        
        </v-btn>
      </v-app-bar>
    
        <!--<v-navigation-drawer app v-model="drawer" temporary dark absolute>-->
      <v-navigation-drawer app v-model="drawer" temporary dark fixed> 
        <v-list-item>
            <v-list-item-avatar>
            <v-img v-if="user.loggedIn == true" :src='user.data.photoURL'></v-img>
            <!--<v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>v-img>{{user.data.photoUrl}}></v-img>-->          
            </v-list-item-avatar>
        </v-list-item>
        <v-list-item link>
            <v-list-item-content>
              <v-list-item-title v-if="user.loggedIn == true" class="title font-weight-bold">{{user.data.displayName}}</v-list-item-title>
              <v-list-item-subtitle v-if="user.loggedIn == true">{{user.data.email}}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="user.loggedIn == true" class="alert warning--text font-weight-medium">{{msg_connect}}</v-list-item-subtitle>
              <v-list-item-subtitle v-else class="alert warning--text font-weight-medium">{{msg_noconnect}}</v-list-item-subtitle>
            </v-list-item-content>  
            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
            <v-list-item v-for="(item, index) in basicm" :key="index" link :to="item.to" :title="item.title">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>    
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
            <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.icon" no-action :title="item.title">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="child in item.items" :key="child.title" link :to="child.to" :title="child.title"> 
                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item> 
            </v-list-group>
        </v-list>
      </v-navigation-drawer>       
    </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { getAuth, signOut } from "firebase/auth"

export default {
  name: 'Cabecera',
  data(){
    return {
      drawer: null,
      dial_reg: false,
      name: '',
      email: null,
      password: null,
      error: null,
      basicm: [
        { title: 'Inicio', icon: 'mdi-home', to: '/' }
      ],    
      items: [
        { title: 'Lista de Tareas', icon: 'mdi-view-dashboard', to: '',
          items: [{ title: 'Modulo Tareas', to: '/tareas' }],
        },
        { title: 'Data', icon: 'mdi-database', active: true,
          items: [
            { title: 'Principal',to: '/datos'},
            { title: 'Creacion',to: '/clientes' },
            { title: 'Lista',to: '/lista_clientes' },
            { title: 'Lista Vuelos Sta Marta',to: '/lista_vuelos_sala_santa_marta' },
          ],
        },
        { title: 'Cargar', icon: 'mdi-folder', to: '',
          items: [
            { title: 'Carga Drive', to: '/botones' },
            { title: 'Carga FireStore', to: '/upload_firestore' },
          ],
        },
        { title: 'Botones', icon: 'mdi-widgets', to: '',
          items: [{ title: 'Modulo Botones', to: '/botones' }],
        },
        { title: 'Grids', icon: 'mdi-grid', to: '',
          items: [{ title: 'Modulo Grids', to: '/grids' }],
        },
        { title: 'About', icon: 'mdi-forum', to: '/about',
          items: [{ title: 'Acerca del Demo', to: '/about' }],
        },
      ]
    }
  },
  methods: {
    
    signOut() {
      const auth = getAuth();
        signOut(auth).then(() => {
        this.$router.replace({path: "/"});
      });
    } 
  },
  computed: {
      ...mapState(['main_title']),
      ...mapState(['btn_access']),
      ...mapState(['btn_exit']),
      ...mapState(['btn_create']),
      ...mapState(['msg_connect']),
      ...mapState(['msg_noconnect']),
      ...mapGetters({user: "user" }),
  }
}
</script>