<template lang="es">
    <v-container grid-list-md>
        <v-row no-gutter class="d-flex wrap"> 
            <v-col cols="12" sm="6">
                <v-card elevation="24" class="md-auto my-6 pa-2">
                    <v-img class="white--text align-end" height="250" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                    <v-card-title>
                        <h2>Esto es un Paisaje</h2>
                    </v-card-title>
                    </v-img>
                    <v-card-subtitle>
                        <h2>Subtitulo de este paisaje atardecer</h2>
                    </v-card-subtitle>          
                    <v-card-text class="text--primary">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis alias velit modi aut mollitia inventore sint iure. Quo alias, totam nulla aliquid fugiat neque blanditiis quasi provident corporis iste quae.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="error" title="Ejecuta una Accion" @click="dialog = true">Accion</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card elevation="24" class="md-auto my-6 pa-2">
                <v-img height="250" src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
                <v-card-title>
                    <h2>Comida Lista para Servir</h2>
                </v-card-title>
                <v-card-text>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis alias velit modi aut mollitia inventore sint iure. Quo alias, totam nulla aliquid fugiat neque blanditiis quasi provident corporis iste quae.</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info" title="Ejecuta una Accion" @click="dialog = true">Accion</v-btn>
                </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- Dialog -->
        <v-dialog v-model="dialog" width="500" persistent>
            <v-card>
                <v-card-title>Este es el titulo de la Accion</v-card-title>
                <v-card-text>Lorem de daetajdlajsdklja ajljflkfjkdlsjfkl</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-btn @click="dialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Fin Dialog -->
    </v-container>
</template>

<script>
export default {
    name: 'Cards',
    data() {
      return {
        dialog: false
      }
    },
}
</script>

