<template>
    <v-container mt-4>
        <Tab_Clientes/>
    </v-container>
</template>

<script>
import Tab_Clientes from '@/components/Tab_Clientes'

export default {
    name: 'Datos',
    data() {
        return {
            //dialog: false
        }
    },
    components: {
        Tab_Clientes
    },
}
</script>