import Vue from 'vue'
import VueRouter from 'vue-router'
import Footer from '../components/Footer.vue'
import Login from '../components/Login'
import Login_Main from '../components/Login_Main'
import Register from '../components/Register'
import RegisterMain from '../components/Register_Main'
import ResetPass from '../components/ResetPass'
import Clientes from '../components/Clientes'
import Lista_Clientes from '../components/Lista_Clientes'
import Lista_Vuelos_Sala_Santa_Marta from '../components/Lista_Vuelos_Sala_Santa_Marta'
import Tab_Clientes from '../components/Tab_Clientes'
import Upload_FireStore from '../components/Upload_FireStore'

// Vistas
import Home from '../views/Home.vue'
import Grids from '../views/Grids.vue'
import Botones from '../views/Botones.vue'
import Tareas from '../views/Tareas-crud.vue'
import Datos from '../views/Datos.vue'
import About from '../views/About.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/login_main',
    name: 'Login_Main',
    component: Login_Main
  },
  {
    path: '/register_main',
    name: 'RegisterMain',
    component: RegisterMain
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetpass',
    name: 'ResetPass',
    component: ResetPass
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes,
    meta: { requiresAuth: true },
  },
  {
    path: '/lista_clientes',
    name: 'Lista_Clientes',
    component: Lista_Clientes,
    meta: { requiresAuth: true },
  },
  {
    path: '/lista_vuelos_sala_santa_marta',
    name: 'Lista_Vuelos_Sala_Santa_Marta',
    component: Lista_Vuelos_Sala_Santa_Marta,
    meta: { requiresAuth: true },
  },
  {
    path: '/tab_clientes',
    name: 'Tab_Clientes',
    component: Tab_Clientes,
    meta: { requiresAuth: true },
  },
  {
    path: '/grids',
    name: 'Grids',
    component: Grids,
    meta: { requiresAuth: true },
  },
  {
    path: '/tareas',
    name: 'Tareas',
    component: Tareas,
    meta: { requiresAuth: true },
  },
  {
    path: '/botones',
    name: 'Botones',
    component: Botones,
    meta: { requiresAuth: true },
  },
  {
    path: '/datos',
    name: 'Datos',
    component: Datos,
    meta: { requiresAuth: true },
  },
  {
    path: '/footer',
    name: 'Footer',
    component: Footer,
    meta: { requiresAuth: true },
  },
  {
    path: '/upload_firestore',
    name: 'Upload_FireStore',
    component: Upload_FireStore,
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if(!store.state.user.loggedIn) {
      next('/login_main')
    } else{
      next()
    }
  } else {
      next()
  }
})

export default router
