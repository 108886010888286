<template>
  <div class="about text-center">
    <h1>This is an about page</h1>
    <v-card color="blue">
      <router-link :to="{query: {seccion: 1}}">1</router-link>
    </v-card>
    <v-card color="blue">
      <router-link :to="{query: {seccion: 2}}">2</router-link>
        <h1 class="display-3 text-center">Acerca de nosotros y de este Demo</h1>
        <h3 class="display-3 text-center">-------------------------</h3>
        <v-row>
            <v-col cols="12" sd="6">
              <p>
                {{text_about_01}}
              </p>
            </v-col>
          <v-col cols="12" sd="6"> 
              <p>
                {{text_about_02}}
              </p>
          </v-col>
        </v-row>
    </v-card>

    <h2 v-for="(item, index) in tareasState" :key="index">{{item}}</h2>
    <h3 >{{contador}}</h3>
    <button @click="aumentarContador(1)">+</button>
    <h3 >{{titulo}}</h3>
  </div>
</template>


<script>
import {mapState, mapMutations} from "vuex";
export default {
  computed:{
    ...mapState('tareas', ['tareasState']),
    ...mapState(['titulo']),
    ...mapState('contador', ['contador']),
    ...mapState(['text_about_01']),
    ...mapState(['text_about_02']),
  },
  methods: {
    ...mapMutations('contador',['aumentarContador'])
  },
  watch:{
    "$route.query.seccion":{
      immediate: true,
      handler(seccion){
        console.log(`Seccion ha cambiado : ${seccion}`)
      }
    }
    
  }
}
</script>