<template>
  <v-container fluid>
    <v-card>
    <v-card-title>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
        ></v-text-field>
    </v-card-title>
        <v-divider dark></v-divider>
        <v-data-table
            :headers="headers"
            :items="lists"
            :search="search"
            sort-by="name"
            class="elevation-3"
            loading
            loading-text="Cargando Datos... Por favor esperar"
            no-data
            no-results
            progress
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{msg_list_1}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" rounded persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="AddClient">{{btn_new}}</v-btn>
                </template>
                <v-card elevation="15" persistent>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.name" label="Nombre"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.email" label="Correo"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.phoneNumber" label="Teléfono"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.address" label="Dirección"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.credate" type="date" label="Creacion"></v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{btn_exit}}</v-btn>
                    <v-btn color="blue darken-1" text @click="save">{{btn_save}}</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px" rounded persistent>
                <v-card>
                    <v-card-title class="text-h5">¿Esta seguro de eliminar este registro?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">{{btn_exit}}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{btn_yes}}</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{item}">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {db} from '../main'
import { collection, query, getDocs, doc, addDoc, updateDoc, deleteDoc } from "firebase/firestore"
import { mapState } from "vuex"

  export default {
    name: 'Lista_Clientes',
    data: () => ({
        search: '',
        dialog: false,
        dialogDelete: false,
        headers: [
        {
          text: 'Id',
          align: 'start',
          filterable: false,
          sortable: false,
          value: 'id',
          class: "grey lighten-1"
        },
        { text: 'Nombre', value: 'name', class: "grey lighten-1" },
        { text: 'Correo', value: 'email', class: "grey lighten-1" },
        { text: 'Telefono', value: 'phoneNumber', class: "grey lighten-1"},
        { text: 'Direccion', value: 'address', class: "grey lighten-1" },
        { text: 'Creacion', value: 'credate', class: "grey lighten-1" },
        { text: 'Acciones', value: 'actions', class: "red lighten-3", sortable: false },
      ],
      lists: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: null,
        phoneNumber: null,
        address: null,
        credate: null,
        //usr_verify: null,
      },
      defaultItem: {
        name: '',
        email: null,
        phoneNumber: null,
        address: null,
        credate: null,
        usr_verify: null,
      },
    }),

    computed: {
      ...mapState(['btn_new']),
      ...mapState(['btn_exit']),
      ...mapState(['btn_save']),
      ...mapState(['btn_yes']),
      ...mapState(['msg_list_1']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    methods: {
        async updClient(val){
          try {
            //var updateRef = db.collection("clientes").doc(val.id);
            await updateDoc(doc(db, 'clientes', val.id), {
            //return updateRef.update({
                name: val.name,
                email: val.email,
                phoneNumber: val.phoneNumber,
                address: val.address,
                credate: val.credate,
            })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
          } catch (error) {
              console.log(error);
          }
        },
        async delClient(val){
          try {
            await deleteDoc(doc(db, 'clientes',val.id)).then(() => {
                console.log("Document successfully deleted!");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
          } catch (error) {
                console.log(error);
          }
        },
        async getClient(){
          try {
            const q = query(collection(db, "clientes"));
            const querySnapshot = await getDocs(q);
            const clievents = [];
            querySnapshot.forEach((doc) => {
                  //console.log(`${doc.id} => ${doc.data()}`);
                  let eventoData = doc.data();
                  eventoData.id = doc.id;
                  clievents.push(eventoData);
                  this.lists = clievents;
            });
          } catch (error) {
              console.log(error);
          }
        },
        async addClient() {
          try {
            if(this.name && this.address && this.email){
              await addDoc(collection(db,'clientes'),{
                name: this.name,
                address: this.address,
                email: this.email,
                phoneNumber: this.phoneNumber,
                credate: this.credate,
                usr_verify: this.checkbox
              });
                  //console.log("Document written with ID: ", docRef.id);
                  this.$refs.observer.reset();
                  this.name = null;
                  this.address = null;
                  this.email = null;
                  this.phoneNumber = null;
                  this.credate = null;
                  this.usr_verify = null;
                  
            }else{
              console.log('Campos Obligatorios');
            }
          } catch (error) {
            console.error("Error adding document: ", error);
            console.error("Error adding document: ", error);
            console.log(error);
          }
        },
      initialize () {
        this.getClient()
        //this.lists = [
        //  {
        //  },
        //]
      },
      editItem (item) {
        this.editedIndex = this.lists.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.lists.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.lists.splice(this.editedIndex, 1)
        this.delClient(this.editedItem)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.lists[this.editedIndex], this.editedItem)
          this.updClient(this.editedItem)
        } else {
          this.lists.push(this.editedItem)
          console.log("push")          
        }
        this.close()
      },
    },
  }
</script>