var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dial_reg),callback:function ($$v) {_vm.dial_reg=$$v},expression:"dial_reg"}},[_c('v-card',{attrs:{"elevation":"10","loading":""}},[_c('v-card-title',{staticClass:"headline font-weight justify-space-between"},[_vm._v(_vm._s(_vm.currentTitle))]),_c('v-container',[(_vm.error)?_c('v-card-text',{staticClass:"red darken-2 white--text"},[_vm._v(_vm._s(_vm.error)+">")]):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.registrar.apply(null, arguments)}}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-divider'),_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Agregar Nombre Usuario*","counter":40,"prepend-inner-icon":"mdi-account","error-messages":errors,"solo":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"E-mail*","prepend-inner-icon":"mdi-mail","error-messages":errors,"solo":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{required: true,digits: 10,
                      regex: /^[0-9]+$/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"phone","label":"Telefono*","counter":10,"prepend-inner-icon":"mdi-phone","error-messages":errors,"solo":"","required":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)})],1),_c('v-window-item',{attrs:{"value":2}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Clave*","prepend-inner-icon":"mdi-key","counter":25,"error-messages":errors,"solo":"","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"passwordv","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Confirme Clave*","prepend-inner-icon":"mdi-key","counter":25,"error-messages":errors,"solo":"","required":""},model:{value:(_vm.passwordv),callback:function ($$v) {_vm.passwordv=$$v},expression:"passwordv"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"value":"1","label":"Autorizacion de manejo de Datos Personales","type":"checkbox","solo":"","required":""},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4 mt-3 mb-2",attrs:{"type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.btn_send))]),_c('v-btn',{staticClass:"mr-4 mt-3 mb-2 white--text",attrs:{"color":"red","to":"/"},on:{"click":function($event){$event.stopPropagation();return _vm.clears.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.btn_exit))])],1)],1)],1)]}}])}),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.step === 1,"text":""},on:{"click":function($event){_vm.step--}}},[_vm._v(_vm._s(_vm.btn_back))]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.step === 3,"color":"primary","depressed":""},on:{"click":function($event){_vm.step++}}},[_vm._v(_vm._s(_vm.btn_next))])],1),_c('v-card-text',[_vm._v(_vm._s(_vm.msg_windinim))]),_c('v-card-text',{staticClass:"small"},[_vm._v(_vm._s(_vm.msg_windfiob))]),_c('v-card-text',[_vm._v(_vm._s(_vm.msg_windapol))]),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }