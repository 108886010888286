import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css';
import TextareaAutosize from 'vue-textarea-autosize';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
//import "firebase/analytics";
import { getAnalytics } from "firebase/analytics";
// Add the Firebase products that you want to use
//import "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
//import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// firebase connection
const firebaseConfig = {
  apiKey: "AIzaSyBDhbV0C9tB4d1cPIaXzdLXS2Ops8SFqg0",
  authDomain: "vue-calendario-5f879.firebaseapp.com",
  projectId: "vue-calendario-5f879",
  storageBucket: "vue-calendario-5f879.appspot.com",
  messagingSenderId: "146356320708",
  appId: "1:146356320708:web:866972f995b1b103344c91",
  measurementId: "G-XKNS1KSS90"
};
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
export const firebase = firebaseApp;
export const analytics = getAnalytics();
export const db = getFirestore(firebaseApp);
export const storage = getStorage();
const fireseg = getAuth();

// Seguridad
onAuthStateChanged(fireseg, user => {
  store.dispatch("fetchUser", user);
});

Vue.config.productionTip = false

Vue.use(TextareaAutosize)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')