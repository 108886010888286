import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
          iconfont: 'md',
        },
      themes: {
        light: {
          primary: '#9C27B0',
          secondary: '#3F51B5',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        },
      },
    },
  });

