<template>
  <v-app> 
    <Cabecera/>
    <v-main class="py-4">
      <v-container fluid>
       <router-view/>
      </v-container>
    </v-main>
    <footer>
      <Footer/>
    </footer>
  </v-app>
</template>

<script>

import Cabecera from '@/components/Cabecera'
import Footer from '@/components/Footer'
export default {
  name: 'App',
    components: {
    Cabecera,
    Footer
  },
  data: () => ({
    //
  }),
};
</script>