<template>
    <v-container centered>
        <h1 class="center text-h4 font-weight-thin">Demo Consulta Viajes en Sala - Tiempo Real - Aeropuerto Santa Marta</h1>
        <v-row class="center" justify="center">
            <v-col class="text-center" cols="12">
                <iframe width=750 height=700 frameborder="0" :src="ruta" allow="fullscreen"></iframe>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios"
export default {
    data() {
        return {
            ruta: 'http://fids-sksm.aerooriente.com.co/aiir/?page_id=122',
         }
    },
    watch: {
        options: {
            handler() {
                this.fetch()
            },
            deep: true,
        },
    },
    methods: {
        fetch() {axios.get('http://fids-sksm.aerooriente.com.co/aiir/wp-admin/admin-ajax.php', {mode: 'cors'})
            .then(response => (
                this.info = response,
                console.log(this.info)
            ))
            .catch(err => {
                console.log(err)
                this.info = err
            })
        },
    },
}
</script>