<template>
  <v-container grid-list-md>
    <v-row no-gutter class="d-flex wrap"> 
      <v-col cols="12" sm="6">
        <v-card elevation="24" class="md-auto my-6 mb-3 pa-2" v-for="(item, index) in listaTareas" :key="index">
          <v-card-title>
            <v-chip class="ma-2 ml-0" color="pink" label text-color="white">
                <v-icon left>mdi-label</v-icon>
                {{item.titulo}}
            </v-chip>
          </v-card-title>
           <v-card-subtitle>
            <h2>{{item.subtitulo}}</h2>
          </v-card-subtitle>          
          <v-card-text class="text--primary">
            <p>{{item.descripcion}}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="warning" @click="Editar(index)">Editar</v-btn>
            <v-btn color="error" @click="EliminarTarea(item.id)">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    <v-col cols="12" sm="6" v-if="formAgregar">
        <v-card elevation="24" class="md-auto my-6 mb-3 pa-2">
            <v-form @submit.prevent="AgregarTarea">
                <v-text-field label="Titulo de Tarea" v-model="titulo"></v-text-field>
                <v-text-field label="Sub Titulo de Tarea" v-model="subtitulo"></v-text-field>
                <v-textarea label="Descripcion de la Tarea" v-model="descripcion"></v-textarea>
                <v-btn color="success" block type="submit">Agregar Tarea</v-btn>
            </v-form>
        </v-card>
    </v-col>
    
    <v-col cols="12" sm="6" v-if="!formAgregar">
        <v-card elevation="24" class="md-auto my-6 mb-3 pa-2">
            <v-form @submit.prevent="EditarTarea">
                <v-text-field label="Titulo de Tarea" v-model="titulo"></v-text-field>
                <v-text-field label="Sub Titulo de Tarea" v-model="subtitulo"></v-text-field>
                <v-textarea label="Descripcion de la Tarea" v-model="descripcion"></v-textarea>
                <v-btn color="warning" block type="submit">Editar Tarea</v-btn>
            </v-form>
        </v-card>
    </v-col>
    
    </v-row>

    <v-snackbar v-model="snackbar">
        {{mensaje}}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Cerrar</v-btn>
        </template>
    </v-snackbar>

  </v-container>
</template>

<script>
export default {
    data () {
        return {
            listaTareas: [
                {id: 1, titulo: 'Titulo #1', descripcion: 'esat es la descripciond de una tarea del ejercicio de vue', subtitulo: 'Subtitulo 1'},
                {id: 2, titulo: 'Titulo #2', descripcion: 'esat es la descripciond de una tarea del ejercicio de vue', subtitulo: 'Subtitulo 2'}
            ],
            titulo: '',
            subtitulo:'',
            descripcion:'',
            snackbar: false,
            mensaje: '',
            formAgregar: true,
            IndexTarea: ''
        }
    },
    methods: {
        AgregarTarea(){
            // console.log(this.titulo, this.subtitulo, this.descripcion);
            if(this.titulo === '' || this.subtitulo === '' || this.descripcion === ''){
                this.snackbar = true
                this.mensaje = 'Llena todos los campos, por favor'
            }else{
                this.listaTareas.push({
                    id: Date.now(),
                    titulo: this.titulo,
                    subtitulo: this.subtitulo,
                    descripcion: this.descripcion
                })
                this.titulo = ''
                this.subtitulo = ''
                this.descripcion = ''
                this.snackbar = true
                this.mensaje = 'Tarea Agregada Satisfactoriamente!!!'
            }
        },
        EliminarTarea(id){
            this.listaTareas = this.listaTareas.filter(e => e.id != id)
        },
        Editar(index){
            this.formAgregar = false
            this.titulo = this.listaTareas[index].titulo
            this.subtitulo = this.listaTareas[index].subtitulo
            this.descripcion = this.listaTareas[index].descripcion
            this.IndexTarea = index
        },
        EditarTarea(){
            this.listaTareas[this.IndexTarea].titulo = this.titulo
            this.listaTareas[this.IndexTarea].subtitulo = this.subtitulo
            this.listaTareas[this.IndexTarea].descripcion = this.descripcion
            this.formAgregar = true
            this.titulo = ''
            this.descripcion = ''
            this.snackbar = true
            this.mensaje = 'Tarea Modificada Satisfactoriamente!!!'
        }
    },
}
</script>

 