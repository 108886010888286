<template>
  <v-container>
    <v-dialog v-model="dial_reg" max-width="500" persistent>
      <v-container>
      <v-card elevation="10" loading>
        <v-card-title class="headline">{{lgus_title}}</v-card-title>       
              <v-card-text v-if="error" class="red darken-2 white--text">{{error}}></v-card-text>
              <v-divider></v-divider>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-container>
              <v-form @submit.prevent="logacc">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">               
                  <v-text-field solo type="email" label="E-mail*" prepend-inner-icon="mdi-account" v-model="email" :error-messages="errors" required></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="password" rules="required|min:7">
                  <v-text-field solo type="password" label="Clave*" prepend-inner-icon="mdi-lock" v-model="password" :error-messages="errors" required></v-text-field>
                </validation-provider>
                  <v-btn class="success mt-4 ml-4 xs" type="submit" :disabled="invalid">{{btn_send}}</v-btn>
                  <v-btn class="error mt-4 ml-4 xs" @click.prevent="clears">{{btn_exit}}</v-btn>
              </v-form>
              </v-container>
            </validation-observer>
            <v-divider class="mt-3"></v-divider>
            <v-btn class="mt-4 mb-4 text-capitalize text-decoration-underline" text @click.stop="clears" to="resetpass">{{btn_fpsw}}</v-btn>         
          <v-divider></v-divider>
          <v-card-text class="center">{{msg_wiusrath}}</v-card-text>
          <v-card-text class="small center">{{msg_windfiob}}</v-card-text>
      </v-card>
      </v-container>
    </v-dialog>
  </v-container>       
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { required, digits, email, min} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} debe de ser de {length} digitos. ({_value_})',
  //message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} no puede estar vacio',
  //message: '{_field_} can not be empty',
})

extend('min', {
  ...min,
  message: '{_field_} no pueder menor de {length} caracteres',
  //message: '{_field_} may not be less than {length} characters',
})

extend('email', {
  ...email,
  message: 'Email debe ser valido',
  //message: 'Email must be valid'
})

export default {
  components: {
      ValidationProvider,
      ValidationObserver,
  },
  data() {
    return {
      dial_reg: true,
      email: null,
      password: null,
      error: null,
      msgevr: false, 
    }
  },
  methods: {
    logacc() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {
        // Signed in
        const msg_varver = userCredential.user.emailVerified;
        this.msgevr = userCredential.user.emailVerified;
        if(msg_varver) {
          //var user = userCredential.user;
          this.password = null;
          this.$refs.observer.reset();
          this.$router.replace({name: "Home"});
        }else{        
          this.error = 'El Usuario no esta verificado, Verifica tu correo y apruebalo'
        }
        // ...
      })
      .catch((error) => {
        //var errorCode = error.code;
        const errorMessage = error.message;
        this.error = errorMessage;
      });
    },
    clears() {
      const msg_varver = this.msgevr;
      if(msg_varver) {
          this.email = null;
          this.password = null;
          this.dial_reg = false;
          this.msgevr = false;
          this.$refs.observer.reset();
          this.$router.replace({name: "Home"});
      }else{const auth = getAuth();
        signOut(auth).then(() => {
            this.email = null;
            this.password = null;
            this.dial_reg = false;
            this.msgevr = null;
            this.$refs.observer.reset();
            this.$router.replace({name: "Home" });
            //console.log(msg_varver);
            //console.log('salida')
        }).catch((error) => {
          // An error happened.
            const errorMessage = error.message;
            this.error = errorMessage;
        });
      }
    },
  },
  computed: {
    ...mapState(['lgus_title']),
    ...mapState(['btn_send']),
    ...mapState(['btn_exit']),
    ...mapState(['btn_fpsw']),
    ...mapState(['msg_wiusrath']),
    ...mapState(['msg_windfiob']),
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
    user: "user",
    })
  }
}
</script>