var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.uplf_title))]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.UploadDoc.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"files","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"type":"file","accept":".csv, .doc, .docx, .xlsx, .xls, .pdf","placeholder":"Cargue sus Documentos","label":"Documentos Seleccionados","multiple":"","show-size":"","counter":"","loading":"","prepend-icon":"mdi-paperclip","error-messages":errors},on:{"change":_vm.clickDoc},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}],null,true),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-3 mb-3 ma-2 white--text",attrs:{"type":"submit","color":"success","loading":_vm.loading_doc,"disabled":invalid,"title":"Cargar Documentos"},on:{"click":[function($event){$event.stopPropagation();_vm.loader_doc = 'loading_doc'},function($event){$event.preventDefault();return _vm.UploadDoc.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"loader_doc",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Cargar Docs "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cloud-upload")])],1)],1)]}}])}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.UploadImg.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"images","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"type":"file","accept":"image/png, image/jpeg, image/bmp","placeholder":"Cargue sus Imagenes","label":"Imagenes Seleccionadas","multiple":"","show-size":"","counter":"","loading":"","rules":_vm.rules,"prepend-icon":"mdi-camera","error-messages":errors},on:{"change":_vm.clickImg},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}],null,true),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-3 mb-3 ma-2 white--text",attrs:{"type":"submit","color":"primary","loading":_vm.loading_img,"disabled":invalid,"title":"Cargar Imagenes"},on:{"click":[function($event){_vm.loader_img = 'loading_img'},function($event){$event.preventDefault();return _vm.UploadImg.apply(null, arguments)}]}},[_vm._v(" Cargar Imagenes "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cloud-upload")])],1),[_c('v-progress-linear',{attrs:{"buffer-value":_vm.bufferValue},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],_c('v-btn',{staticClass:"mt-3 mb-3 ma-2 white--text",attrs:{"color":"black","title":"Listar todas las imagenes"},on:{"click":function($event){$event.preventDefault();return _vm.ListAllDoc.apply(null, arguments)}}},[_vm._v("Listar Imagenes")]),_c('v-row',_vm._l((_vm.list_img),function(item,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":item,"aspect-ratio":"1","contain":"","max-height":"125"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5","title":"Loading"}})],1)]},proxy:true}],null,true)})],1)}),1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }