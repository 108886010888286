<template>
    <v-dialog v-model="dial_reg" max-width="500" persistent>
        <v-card elevation="10" loading>
            <v-card-title class="headline font-weight justify-space-between">{{currentTitle}}</v-card-title>
            <v-container>
              <v-card-text v-if="error" class="red darken-2 white--text">{{error}}></v-card-text>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="registrar">
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <v-divider></v-divider>
                          <validation-provider v-slot="{ errors }" name="Name" rules="required|max:40">
                            <v-text-field type="text" label="Agregar Nombre Usuario*" v-model="name" :counter="40" prepend-inner-icon="mdi-account" :error-messages="errors" solo required></v-text-field>
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                            <v-text-field type="email" label="E-mail*" v-model="email" prepend-inner-icon="mdi-mail" :error-messages="errors" solo required></v-text-field>
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="phoneNumber" :rules="{required: true,digits: 10,
                          regex: /^[0-9]+$/}">
                            <v-text-field type="phone" label="Telefono*" v-model="phoneNumber" :counter="10" prepend-inner-icon="mdi-phone" :error-messages="errors" solo required></v-text-field>
                          </validation-provider>
                    </v-window-item>
                    <v-window-item :value="2">
                      <validation-provider v-slot="{ errors }" name="password" rules="required|min:8">
                        <v-text-field type="password" label="Clave*" v-model="password" prepend-inner-icon="mdi-key" :counter="25" :error-messages="errors" solo required></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="passwordv" rules="required|min:8">
                        <v-text-field type="password" label="Confirme Clave*" v-model="passwordv" prepend-inner-icon="mdi-key" :counter="25" :error-messages="errors" solo required></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" rules="required" name="checkbox">
                        <v-checkbox v-model="checkbox" :error-messages="errors" value="1" label="Autorizacion de manejo de Datos Personales" type="checkbox" solo required></v-checkbox>
                      </validation-provider>

                      <v-btn class="mr-4 mt-3 mb-2" type="submit" :disabled="invalid">{{btn_send}}</v-btn>
                      <v-btn class="mr-4 mt-3 mb-2 white--text" color="red" @click.stop="clears" to="/">{{btn_exit}}</v-btn>
                    </v-window-item>
                  </v-window>
                </v-form>
              </validation-observer>            
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn :disabled="step === 1" text @click="step--">{{btn_back}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="step === 3" color="primary" depressed @click="step++" >{{btn_next}}</v-btn>
              </v-card-actions>
                <v-card-text>{{msg_windinim}}</v-card-text>
                <v-card-text class="small">{{msg_windfiob}}</v-card-text>
                <v-card-text>{{msg_windapol}}</v-card-text>
              <v-divider></v-divider>
            </v-container>
        </v-card>
    </v-dialog>       
</template>


<script>
import { mapState } from "vuex"
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, signOut } from "firebase/auth"
import { required, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} debe de ser de {length} digitos. ({_value_})',
  //message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} no puede estar vacio',
  //message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} no pude ser mas grande de {length} caracteres',
  //message: '{_field_} may not be greater than {length} characters',
})

extend('min', {
  ...min,
  message: '{_field_} no pueder menor de {length} caracteres',
  //message: '{_field_} may not be less than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} no corresponde a un telefono {regex}',
  //message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email debe ser valido',
  //message: 'Email must be valid'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
    data(){
    return {
      dial_reg: true,
      name: '',
      email: null,
      password: null,
      passwordv: null,
      error: null,
      phoneNumber: null,
      checkbox: null,
      step: 1,
    }
  },
  methods: {
    registrar() {
      const auth = getAuth()
      if(this.password == this.passwordv){
        createUserWithEmailAndPassword(auth, this.email,this.password)
        .then((userCredential) => {
          // Signed in
            //var user = userCredential.user;
            updateProfile(userCredential.user, {displayName: this.name, phoneNumber: this.phoneNumber})
            .then(() => {
            this.veryuser();
            this.dial_reg = false;
            this.name = null;
            this.phoneNumber = null;
            this.email = null;
            this.password = null;
            this.checkbox = 1;
            this.signoutreg();
            });
            // ...
        })
          .catch((error) => {
            //var errorCode = error.code;
            const errorMessage = error.message;
            //console.log(errorCode);
            //console.log(errorMessage);
            this.error = errorMessage;
            this.$refs.observer.reset();
            // ..
          });
        }else{this.error = 'Las claves no son iguales, rectifique'}
    },
    submit () {
      this.$refs.observer.validate()
    },
    clears () {
        this.name = null;
        this.phoneNumber = null;
        this.email = null;
        this.password = null;
        this.checkbox = 1;
        this.dial_reg = false;
        this.$refs.observer.reset();
    },
    signoutreg() {
      const auth = getAuth()
      signOut(auth).then(() => {
        this.$refs.observer.reset();
        this.$router.replace({ name:"Home" });
      });
    },
    veryuser() {
      const auth = getAuth()
      auth.languageCode = 'es';
      sendEmailVerification(auth.currentUser).then(function() {
        // Email sent.
      }).catch(function(error) {
        // An error happened.
        const errorMessage = error.message;
        this.error = errorMessage;
      });
    }
  },
  computed: {
    ...mapState(['reus_title']),
    ...mapState(['btn_send']),
    ...mapState(['btn_exit']),
    ...mapState(['btn_fpsw']),
    ...mapState(['btn_next']),
    ...mapState(['btn_back']),
    ...mapState(['msg_windapol']),
    ...mapState(['msg_windfiob']),
    ...mapState(['msg_windinim']),
    currentTitle () {
      switch (this.step) {
        case 1: return 'Información para Registro del Usuario'
        case 2: return 'Crear Clave de Acceso'
        default: return 'Cuenta Creada'
      }
    },
  }
}
</script>
