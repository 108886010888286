<template>
    <v-container fluid>
        <h1>{{uplf_title}}</h1>
        <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="UploadDoc">
            <validation-provider v-slot="{ errors }" name="files" rules="required">
            <v-file-input v-model="files" type="file" accept=".csv, .doc, .docx, .xlsx, .xls, .pdf" placeholder="Cargue sus Documentos" label="Documentos Seleccionados"
                multiple show-size counter loading prepend-icon="mdi-paperclip" @change="clickDoc" :error-messages="errors">
                <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
            </v-file-input>
            </validation-provider>
                <v-btn
                type="submit"
                color="success"
                class="mt-3 mb-3 ma-2 white--text"
                :loading="loading_doc"
                :disabled="invalid" 
                @click.stop="loader_doc = 'loading_doc'"
                @click.prevent="UploadDoc"
                title="Cargar Documentos"          
                >
                Cargar Docs
                    <v-icon right dark>mdi-cloud-upload</v-icon>
                    <template v-slot:loader_doc>
                        <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
        </v-form>
        </validation-observer>
        <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="UploadImg">
            <validation-provider v-slot="{ errors }" name="images" rules="required">
            <v-file-input v-model="images" type="file" accept="image/png, image/jpeg, image/bmp" placeholder="Cargue sus Imagenes"
                label="Imagenes Seleccionadas" multiple show-size counter loading :rules="rules" prepend-icon="mdi-camera" @change="clickImg" :error-messages="errors">
                <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
            </v-file-input>
            </validation-provider>
                <v-btn
                type="submit"
                color="primary"
                class="mt-3 mb-3 ma-2 white--text"
                :loading="loading_img"
                :disabled="invalid"                  
                @click="loader_img = 'loading_img'"
                @click.prevent="UploadImg"
                title="Cargar Imagenes"  
                >
                Cargar Imagenes
                    <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
                <template>
                    <v-progress-linear
                        v-model="value"
                        :buffer-value="bufferValue"
                    ></v-progress-linear>
                </template>
                <v-btn color="black" @click.prevent="ListAllDoc" class="mt-3 mb-3 ma-2 white--text" title="Listar todas las imagenes">Listar Imagenes</v-btn>
                <v-row>
                    <v-col v-for="(item, index) in list_img" :key="index" class="d-flex child-flex" cols="4">
                        <v-img :src="item" aspect-ratio="1" class="grey lighten-2" contain max-height="125">
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                    title="Loading"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>   
                </v-row>
        </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
import { mapState } from "vuex"
import { storage } from '../main'
import { ref, uploadBytesResumable, getDownloadURL, listAll, list } from 'firebase/storage'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} no puede estar vacio',
  //message: '{_field_} can not be empty',
})

export default {
    data () {
        return {
        imagen: null,
        docume: null,
        disabled: true,
        loader_doc: null,
        loader_img: null,
        loading_doc: false,
        loading_img: false,
        error: null,
        uploader: 0,
        value: 0,
        bufferValue: 20,
        interval: 0,
        totimagen:0,
        files: [],
        images: [],
        list_img: [],
        events: [],
        rules: [],
    }
    },
    computed: {
        ...mapState(['uplf_title']),   
    },
    methods: {
        clickImg(){
            for (var i = 0; i < this.images.length; i++) {
                    this.imagen = this.images[i];
                    //console.log(this.imagen)
            }
            for (var k = 0; k < this.images.length; k++) {
                    var totimagen = this.images[k].size;
                    console.log(totimagen)
                    //console.log(k,value)
                    this.rules=[totimagen => 0  || totimagen < 2000000 || console.log('dentro de la regla ',[k]) || 'El Tamaño de cada imagen debe ser menor a 2 MB!',]       
            }       
        },
        UploadImg(){
            for (var i = 0; i < this.images.length; i++) {
                this.imagen = this.images[i];
                //console.log(this.imagen)               
                const file = this.imagen
                const metadata = {contentType: 'image/jpeg image/png'};
                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'imagenes/' + file.name);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    }
                
                
                }, 
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    // ...
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    }
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    });
                }
                );
            }
            this.images=[]; 
        },
        clickDoc(){
            for (var i = 0; i < this.files.length; i++) {
                this.docume = this.files[i];
                console.log(this.docume)
            }  
        },
        UploadDoc(){
            for (var i = 0; i < this.files.length; i++) {
                this.docume = this.files[i];
                //console.log(this.docume)               
                const file = this.docume
                // Create the file metadata
                const metadata = {contentType: 'doc/pdf doc/docx doc/xlsx'};
                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'documentos/' + file.name);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    }
                }, 
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    // ...
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    }
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    });
                }
                );
            }
            this.files=[];
        },
        startBuffer () {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
            this.value = 50
            this.bufferValue += Math.random() * (15 - 5) + 6
            }, 2000)
        },
        async ListAllDoc(){
            const events = []; 
            const listRef = ref(storage, '/imagenes'); // Create a reference under which you want to list
            console.log(listRef);

            listAll(listRef).then(res => {res.items.forEach((itemRef) => {
                    console.log(itemRef);
                    events.push(getDownloadURL(itemRef));
  
                
                })}
            );
            
            this.list_img = events;

            // Find all the prefixes and items.
            listAll(listRef).then(res => {
                res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
                console.log('folders',folderRef)
                console.log("en folders")
                events.push(res);
                });
                res.items.forEach(itemRef => {
                // All the items under listRef.
                    itemRef.getDownloadURL().then((url) => { 
                        console.log("en url")
                        console.log(url)
                        events.push(itemRef);
                        //console.log('eventos',events)
                        //console.log(events.length) // 5                                                                    
                    })             
                });
                this.list_img = events;
                console.log("al final") 
                console.log(this.list_img);
            }).catch((error) => {
                // Uh-oh, an error occurred!
                this.error = error;
            });
        },
        async pageTokenExample(){
            // Create a reference under which you want to list
            //const storage = getStorage();
            const listRef = ref(storage, 'imagenes/');

            // Fetch the first page of 100.
            //const firstPage = await list(listRef, { maxResults: 100 });
            await list(listRef, { maxResults: 100 });
            // Use the result.
            // processItems(firstPage.items)
            // processPrefixes(firstPage.prefixes)

            // Fetch the second page if there are more elements.
            //if (firstPage.nextPageToken) {
            //    const secondPage = await list(listRef, {
            //    maxResults: 100,
            //   pageToken: firstPage.nextPageToken,
            //  });
                //processItems(secondPage.items)
                //processPrefixes(secondPage.prefixes)
            //}
        },
        },
    watch: {
        loader_doc () {
            const l = this.loader_doc
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 3000)
            this.loader_doc = null
            },
        loader_img () {
            const k = this.loader_img
            this[k] = !this[k]
            setTimeout(() => (this[k] = false), 3000)
            this.loader_img = null
            },
        value (val) {
            if (val < 100) return
            this.value = 0
            this.bufferValue = 10
            this.startBuffer()
        },
    },
    mounted () {
    this.startBuffer()
    },
    beforeDestroy () {
    clearInterval(this.interval)
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
}
</script>