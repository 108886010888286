<template>
  <v-container grid-list-md>
    <v-row no-gutter class="d-flex wrap"> 
        <v-col cols="12" sm="6">
            <v-card elevation="24" class="md-auto my-6 mb-3 pa-2">
            <v-card-title>
                <v-chip class="ma-2 ml-0" color="blue" label text-color="white">
                    <v-icon left>mdi-label</v-icon>
                    {{msg_segu_1}}
                </v-chip>
            </v-card-title>
            <v-card-subtitle>
                <h2>{{msg_segu_2}}</h2>
            </v-card-subtitle>          
            <v-card-text class="text--primary">
                <p>{{msg_segu_3}}</p>
            </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="formAgregar">
            <v-card elevation="24" class="md-auto my-6 mb-3 pa-2">
                <v-card-title class="headline">Registrate</v-card-title>
                <v-form>
                    <v-btn class="mt-4" color="success" block rounded elevation="5" height="50" to="/Register">
                        <v-icon class="mr-4" color="white" left large>mdi-at</v-icon>Correo</v-btn>
                    <v-btn class="mt-4 white--text" color="blue darken-4" block rounded elevation="5" height="50" @click.prevent="LoginFck">
                        <v-icon class="mr-3" color="white" left large>mdi-facebook</v-icon>Facebook
                    </v-btn>
                    <v-btn class="mt-4" color="white" block rounded elevation="5" height="50" @click.prevent="LoginGoogle">
                        <v-icon class="mr-3" left large>mdi-google</v-icon>Google</v-btn>
                </v-form>
            </v-card>
        </v-col>      
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth"

export default {
    name: 'Register_Main',
    data() {
        return {
            formAgregar: true,
            error: null,
        }
    },
    methods: {
        LoginGoogle() {
            const auth = getAuth();
            auth.useDeviceLanguage();
            const Googleprovider = new GoogleAuthProvider();
            Googleprovider.addScope('profile');
            Googleprovider.addScope('email');
            //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            //provider.addScope('https://www.googleapis.com/auth/drive.appdata');
            Googleprovider.addScope('https://www.googleapis.com/auth/drive.file');
            signInWithPopup(auth, Googleprovider)
            .then((result) => {
                //var credential = result.credential;
                // This gives you a Google Access Token. You can use it to access the Google API.
                //const token = credential.accessToken;
                // The signed-in user info.
                //const user = result.user;
                //console.log(user)
                this.error = result.assign.emailVerified
                // ...
            }).catch((error) => {
                // Handle Errors here.
                //var errorCode = error.code;
                const errorMessage = error.message;
                this.error = errorMessage;
                // The email of the user's account used.
                const email = error.email;
                this.error = email;
                // The firebase.auth.AuthCredential type that was used.
                const credential = error.credential;
                this.error = credential;
                // ...
            });
        },
        LoginFck() {
            const auth = getAuth();
            auth.useDeviceLanguage();
            const Facebookprovider = new FacebookAuthProvider();
            Facebookprovider.addScope('email');
            Facebookprovider.addScope('public_profile');
            //provider.addScope('user_birthday');
            Facebookprovider.addScope('instagram_basic');
            //.addScope('user_location');
            signInWithPopup(auth, Facebookprovider)
            .then((result) => {
                const credential = result.credential;
                this.error = credential;
                // The signed-in user info.
                // const user = result.user;
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // const accessToken = credential.accessToken;
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                //var errorCode = error.code;
                const errorMessage = error.message;
                this.error = errorMessage;
                // The email of the user's account used.
                const email = error.email;
                this.error = email;
                // The firebase.auth.AuthCredential type that was used.
                const credential = error.credential;
                this.error = credential;
                // ...
            });
        },
    },
    computed: {
        ...mapState(['msg_segu_1']),
        ...mapState(['msg_segu_2']),
        ...mapState(['msg_segu_3']),
    }   
}
</script>