<template>
    <v-container>
        <v-card elevation="10" max-width="1200" rounded>
            <v-tabs v-model="tab" background-color="indigo darken-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="(item, index) in tabula" :key="index">{{item.text}}
                <v-icon>{{item.icon}}</v-icon>         
            </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in tabula" :key="index">        
                <v-card flat v-if="item.page == 1">
                <!--<v-card-text>{{item.text}}</v-card-text>-->
                <Clientes/>
                </v-card>
                <v-card flat v-if="item.page == 2">
                <!--<v-card-text>{{item.text}}</v-card-text>-->
                <Lista_Clientes/>
                </v-card>
                <v-card flat v-if="item.page == 3">
                <!--<v-card-text>{{item.text}}</v-card-text>-->
                <Tareas/>
                </v-card>
            </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>      
</template>

<script>
import Clientes from '@/components/Clientes'
import Lista_Clientes from '@/components/Lista_Clientes'
import Tareas from '../views/Tareas-crud'

export default {
    name: 'Tab_Clientes' ,
    components:{
        Clientes,
        Lista_Clientes,
        Tareas
    },
    data: () => ({
        page: 1,
        tab: null,
      tabula: [
        { text: 'Cliente', page: 1,icon: 'mdi-human-queue'},
        { text: 'Listado', page: 2, icon: 'mdi-view-list-outline'},
        { text: 'Tareas', page: 3, icon: 'mdi-account-box'},
        ],
    }),
}
</script>

