<template>
  <v-footer dark padless elevation="5">
    <v-card flat tile class="indigo lighten-1 white--text text-center flex">
      <v-card-text>
        <v-btn v-for="(icon, icons) in icons" :key="icons" :href="icon.href" :title="icon.title" class="mx-4 white--text" icon>
          <v-icon size="24px">{{ icon.icon }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        {{text_footer_01}}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>{{text_footer_02}}</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";

  export default {
      name: 'Footer',
    data: () => ({
      icons: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/quantumsdata',
          title: 'Facebook',
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/quantumsdata',
          title: 'Twitter',
        },
        {
          icon: 'mdi-linkedin',
          href: 'https://www.linkedin.com/company/quantumsdata',
          title: 'LinkedIn',
        },
        {
          icon: 'mdi-instagram',
          href: 'https://www.instagram.com/quantumsdata',
          title: 'Instagram'
        },
      ],
    }),
    computed: {
      ...mapState(['text_footer_01']),
      ...mapState(['text_footer_02']),
    }
  }
</script>