<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col cols="9">
        <v-card class="primary" outlined tile>.col-9</v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="secondary" outlined tile>.col-4<br>Since 9 + 4 = 13 &gt; 12,
         this 4-column-wide div gets wrapped onto a new line as one contiguous unit.</v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="error" outlined tile>.col-6<br>Subsequent columns continue along the new line.
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5 secondary d-flex align-center mb-6 d-flex justify-center mb-6" style="height:300px">
      <v-col class="pink lighten-3 white--text" cols="3"> 
        <h1>Centrado</h1>
      </v-col>
      <v-col class="pink lighten-1 white--text" cols="3"> 
        <h1>Centrado 1</h1>
        <v-card class="secondary" outlined tile>
         <h1>Centrado 1</h1>
         Otas vcard<br>Since 9 + 4 = 13 &gt; 12,
         this 4-column-wide div </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col> 
        <h1 class="display-1 text-uppercase">texto digital de prueba</h1>
        <h1 class="display-2 primary pa-5 mt-5">texto digital de prueba</h1>
        <h1 class="display-3 text-center">texto digital de prueba</h1>
        <h1 class="display-4">texto digital de prueba</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="n in 25" :key="n" cols="auto">
        <v-card :elevation="n - 1" height="100" width="100" class="secondary">
          <v-row class="fill-height" align="center" justify="center" v-text="n - 1"></v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sd="6">
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit necessitatibus sunt quo saepe? Aperiam, laborum voluptatem placeat incidunt aliquam reprehenderit dignissimos voluptates quibusdam eveniet corporis eos qui eligendi assumenda eius?
          </p>
      </v-col>
      <v-col cols="12" sd="6"> 
          <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit necessitatibus sunt quo saepe? Aperiam, laborum voluptatem placeat incidunt aliquam reprehenderit dignissimos voluptates quibusdam eveniet corporis eos qui eligendi assumenda eius?
          </p>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    name: 'Grids',

  }
</script>
