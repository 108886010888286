export default {
    namespaced: true,
    state: {
        tareasState: ['tarea 1', 'tarea 2', 'tarea 3', 'tarea 4', 'tarea 5', 'tarea 6', 'tarea 7']
    },
    mutations: {

    },
    actions: {

    },
    getters: {

    },
}