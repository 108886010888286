<template>
  <v-container fluid>
    <v-dialog v-model="dial_reg" max-width="500" persistent>
      <v-card elevation="20" class="md-auto my-6 mb-3 pa-2" loading title="Loading">
        <v-card-title class="headline">{{lgus_title}}</v-card-title>  
              <v-card-text v-if="error" class="red darken-2 white--text">{{error}}></v-card-text>
              <v-divider></v-divider>
              <v-form>
                <v-btn class="mt-4" color="success" block rounded elevation="5" height="50" to="login">
                    <v-icon class="mr-4" color="white" left large>mdi-at</v-icon>Correo</v-btn>
                <v-btn class="mt-4 white--text" color="blue darken-4" block rounded elevation="5" height="50" @click="LoginFck">
                    <v-icon class="mr-3" color="white" left large>mdi-facebook</v-icon>Facebook
                </v-btn>
                <v-btn class="mt-4" color="white" block rounded elevation="5" height="50" @click="LoginGoogle">
                    <v-icon class="mr-3" left large>mdi-google</v-icon>Google</v-btn>
                <v-btn class="error mt-4 mb-4" block rounded elevation="5" height="50" @click.prevent="clears">{{btn_exit}}</v-btn>
              </v-form>
            <v-divider class="mt-4"></v-divider>        
          <v-card-text class="center">{{msg_wiusrath}}</v-card-text>
          <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>       
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth"

export default {
  data() {
    return {
      dial_reg: true,
      error: null,
    }
  },
  methods: {
    LoginGoogle() {
      const auth = getAuth();
      auth.useDeviceLanguage();
      const Googleprovider = new GoogleAuthProvider();
      signInWithPopup(auth, Googleprovider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        this.error = token,credential,user;
        this.clears();
        console.log(getAuth())
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        this.error = errorCode,errorMessage;
        // The email of the user's account used.
        const email = error.email;
        this.error = email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        this.error = credential;
      });
    },
    LoginFck() {
      const auth = getAuth();
      auth.useDeviceLanguage();
      const Facebookprovider = new FacebookAuthProvider();
      signInWithPopup(auth, Facebookprovider).then((result) => {
        // The signed-in user info.
        const user = result.user;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        this.error = user,accessToken,credential;
        this.clears();
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        this.error = errorCode,errorMessage;
        // The email of the user's account used.
        const email = error.email;
        this.error = email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        this.error = credential;
      });
    },
    clears() {
      this.dial_reg = false;
      this.$router.replace({name: "Home"});
      //console.log('salida')
    },
  },
  computed: {
    ...mapState(['lgus_title']),
    ...mapState(['btn_exit']),
    ...mapState(['msg_wiusrath']),
    ...mapGetters({user: "user",})
  }
}
</script>