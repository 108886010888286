<template>
    <v-container mt-5>
        <v-row>
            <v-col>
                <v-btn>Texto</v-btn>
                <v-btn class="primary">Texto</v-btn>
                <v-btn dark class="blue">Texto</v-btn>

                <v-btn class="secondary" plain>Sin fondo</v-btn>
                <v-btn class="warning" large>Largo</v-btn>
                <v-btn class="warning" small>Pequeño</v-btn>
                <v-btn class="warning" large :disabled="true">Largo</v-btn>

                <v-btn class="info" depressed large>Depresed</v-btn>
                <v-btn class="warning">
                    <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn class="primary">
                    <v-icon>mdi-star</v-icon>
                </v-btn>
                <v-btn icon>  
                    <v-icon color="pink">mdi-cached</v-icon>
                </v-btn>

                <v-btn class="mx-2" fab dark color="teal">
                    <v-icon dark>
                        mdi-format-list-bulleted-square
                    </v-icon>
                </v-btn>

                <v-btn class="mx-2" fab dark small color="teal">
                    <v-icon dark>
                        mdi-format-list-bulleted-square
                    </v-icon>
                </v-btn>

                <v-btn
                :loading="loading"
                :disabled="loading"
                color="blue-grey"
                class="ma-2 white--text"
                @click="loader = 'loading'"
                >
                Upload
                    <v-icon
                    right
                    dark
                    >
                    mdi-cloud-upload
                    </v-icon>
                </v-btn>
                <v-btn color="success">
                    Informacion
                    <v-icon class="ml-2" dark>
                        mdi-format-list-bulleted-square
                    </v-icon>
                </v-btn>
                <v-btn color="success">
                    <v-icon left>
                        mdi-format-list-bulleted-square
                    </v-icon>
                    Informacion
                </v-btn>
                <v-btn color="success" outlined>
                    <v-icon right>
                        mdi-format-list-bulleted-square
                    </v-icon>
                    Informacion
                </v-btn>
                <v-btn color="success" outlined block>
                    <v-icon right>
                        mdi-format-list-bulleted-square
                    </v-icon>
                    Informacion
                </v-btn>
                              

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
   export default {
    name: 'Botones',
    data () {
      return {
        disabled: true,
        loader: null,
        loading: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
      }
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
  }
</script>