import Vue from 'vue'
import Vuex from 'vuex'
import tareas from '@/modules/Tareas'
import contador from '@/modules/Contador'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    titulo: 'Este es titulo desde el archivo principal',
    main_title: 'Aplicativo Demo Firebase DB Quantums Data',
    reus_title: 'Formulario para Registro del Usuario',
    lgus_title: 'Acceso a tu Cuenta',
    rsps_title: 'Restablecer la Clave',
    mdcl_title: 'Formulario Clientes',
    sbdu_title: 'Actualizacion de Datos',
    uplf_title: 'Carga de Archivos a File Store',
    msg_vermail: 'El Usuario no esta verificado, Verifique su correo',
    msg_connect: 'Usted esta Conectado',
    msg_noconnect: 'Usted No esta Conectado',
    msg_wiusrath: 'Digite sus credenciales de usuario autorizado',
    msg_windfiob: ' * Indica que son campos obligatorios',
    msg_windapol: 'Al crear tu cuenta aceptas nuestros Términos y Condiciones y Política de Tratamiento de Datos.',
    msg_windinim: 'Digite toda la informacion que es importante',
    msg_windsdml: 'Te llegara un correo para ejecutar proceso',
    msg_list_1: 'Listado de Información',
    msg_segu_1: 'Opciones de seguridad para tu acceso',
    msg_segu_2: 'Como deseas continuar',
    msg_segu_3: 'Seleccione tu tipo de autenticacion',
    btn_access: 'Ingresar',
    btn_exit: 'Salir',
    btn_create: 'Registrarme',
    btn_send: 'Enviar',
    btn_fpsw: '¿Olvido su contraseña?',
    btn_delete: 'Eliminar',
    btn_new: 'Nuevo',
    btn_save: 'Guardar',
    btn_yes: 'Si',
    btn_not: 'No',
    btn_next: 'Siguiente',
    btn_back: 'Atras',
    text_about_01: 'Somos Quantums Data, empresa dedicada al análisis de datos y desarrollamos soluciones como estas para que los clientes analicen su información de manera segura y confiable ',
    text_about_02: 'Este demo nos muestra el uso de una base de datos segura en la nube moderna, con autenticación federada aplicada, utilizando tecnologías Google y otros usos utilizando Vue.js y Java Script como Framework',
    text_footer_01:'Todas las marcas aqui mencionadas pertenecen a sus respectivas compañias.',
    text_footer_02:'Quantums Data - Inteligencia Artificial - Analisis de Datos',
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          providerData: user.providerData,
          uid: user.uid
        });
      } else {
        commit("SET_USER", null);
      }
    }
  },
  modules: {
    tareas, contador
  }
})
