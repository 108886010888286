<template>
  <v-container justify="center">
    <QDWellcome></QDWellcome>
    <Cards></Cards>
    <Calendario v-if="user.loggedIn == true"></Calendario>
  </v-container>
</template>

<script>
import Calendario from '@/components/Calendario'
import Cards from '@/components/Cards'
import QDWellcome from '@/components/QD_Wellcome'
import { mapGetters } from "vuex";

export default {
  name: 'Home',
  data() {
    return {
      //dialog: false
    }
  },
  components: {
    Calendario,
    Cards,
    QDWellcome
  },
  computed: {
    ...mapGetters({user: "user",})
  },
}
</script>
