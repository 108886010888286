var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v(_vm._s(_vm.mdcl_title))]),_c('v-card',{attrs:{"elevation":"15","loading":"","max-width":"1200","rounded":""},model:{value:(_vm.dial_reg),callback:function ($$v) {_vm.dial_reg=$$v},expression:"dial_reg"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.sbdu_title))]),_c('v-container',[(_vm.error)?_c('v-card-text',{staticClass:"red darken-2 white--text"},[_vm._v(_vm._s(_vm.error)+">")]):_vm._e(),_c('v-divider'),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addClient.apply(null, arguments)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grey lighten-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Agregar Nombre Usuario*","counter":80,"error-messages":errors,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"E-mail*","error-messages":errors,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{required: true,digits: 10,
            regex: /^[0-9]+$/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"phone","label":"Telefono*","counter":10,"error-messages":errors,"required":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required|min:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Direccion*","counter":40,"error-messages":errors,"required":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"credate","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"date","label":"Fecha Creacion*","counter":10,"error-messages":errors,"required":""},model:{value:(_vm.credate),callback:function ($$v) {_vm.credate=$$v},expression:"credate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"ml-5",attrs:{"error-messages":errors,"value":"0","label":"Datos Verificados","type":"checkbox","required":""},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"mt-4 center",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","title":"Crear Registro","disabled":invalid}},[_vm._v(_vm._s(_vm.btn_new))])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"title":"Eliminar Registro","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.delClient.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.btn_delete))])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"title":"Salir del Modulo","to":"/"},on:{"click":function($event){$event.stopPropagation();return _vm.clears.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.btn_exit))])],1)],1)],1)]}}])})],1),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }