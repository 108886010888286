<template>
    <v-container fluid>
        <h2>{{mdcl_title}}</h2>
        <v-card v-model="dial_reg" elevation="15" loading max-width="1200" rounded>
            <v-card-title class="headline">{{sbdu_title}}</v-card-title>
          <v-container>
            <v-card-text v-if="error" class="red darken-2 white--text">{{error}}></v-card-text>
            <v-divider></v-divider>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form @submit.prevent="addClient">
            <v-row no-gutters>
              <v-col cols="12" class="grey lighten-3">
              <validation-provider v-slot="{ errors }" name="Name" rules="required|max:80">
                <v-text-field type="text" label="Agregar Nombre Usuario*" v-model="name" :counter="80" :error-messages="errors" required></v-text-field>
              </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-text-field type="email" label="E-mail*" v-model="email" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="ml-2" cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="phoneNumber" :rules="{required: true,digits: 10,
                regex: /^[0-9]+$/}">
                  <v-text-field type="phone" label="Telefono*" v-model="phoneNumber" :counter="10" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="address" rules="required|min:7">
                  <v-text-field type="text" label="Direccion*" v-model="address" :counter="40" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="credate" rules="required|max:10">
                  <v-text-field type="date" label="Fecha Creacion*" v-model="credate" :counter="10" :error-messages="errors" required></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
              <validation-provider v-slot="{ errors }" rules="required" name="checkbox">
                <v-checkbox class="ml-5" v-model="checkbox" :error-messages="errors" value="0" label="Datos Verificados" type="checkbox" required></v-checkbox>
              </validation-provider>
              </v-col>            
            </v-row>
            <v-divider></v-divider>
              <v-row class="mt-4 center" justify="center" no-gutters>
                <v-col cols="6" md="4">
                  <v-btn class="mr-4" type="submit" title="Crear Registro" :disabled="invalid">{{btn_new}}</v-btn>
                </v-col>
                <v-col cols="6" md="4">
                  <v-btn class="mr-4" title="Eliminar Registro" @click.prevent="delClient" :disabled="invalid">{{btn_delete}}</v-btn>
                </v-col>
                <v-col cols="6" md="4">  
                  <v-btn class="mr-4" title="Salir del Modulo" @click.stop="clears" to="/">{{btn_exit}}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
            </v-container>
            <v-divider></v-divider>
        </v-card>
    </v-container>
</template>

<script>
import { mapState } from "vuex"
import { required, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {db} from '../main'
import { collection, doc, addDoc, deleteDoc } from "firebase/firestore"

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} debe de ser de {length} digitos. ({_value_})',
  //message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} no puede estar vacio',
  //message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} no pude ser mas grande de {length} caracteres',
  //message: '{_field_} may not be greater than {length} characters',
})

extend('min', {
  ...min,
  message: '{_field_} no pueder menor de {length} caracteres',
  //message: '{_field_} may not be less than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} no corresponde a un telefono {regex}',
  //message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email debe ser valido',
  //message: 'Email must be valid'
})
export default {
    name: 'Clientes',
    data() {
        return {
        dial_reg: true,
        name: '',
        email: null,
        phoneNumber: null,
        address: null,
        credate: null,
        error: null,
        checkbox: 0,
      }
    },
    methods: {
      async addClient() {
          try {
            if(this.name && this.address && this.email){
              await addDoc(collection(db,'clientes'),{
                name: this.name,
                address: this.address,
                email: this.email,
                phoneNumber: this.phoneNumber,
                credate: this.credate,
                usr_verify: this.checkbox
              });
                  //console.log("Document written with ID: ", docRef.id);
                  this.$refs.observer.reset();
                  this.name = null;
                  this.address = null;
                  this.email = null;
                  this.phoneNumber = null;
                  this.credate = null;
                  this.usr_verify = null;
                  
            }else{
              console.log('Campos Obligatorios');
            }
          } catch (error) {
            console.error("Error adding document: ", error);
            console.error("Error adding document: ", error);
            console.log(error);
          }
      },
      async delClient(val){
        try {
          await deleteDoc(doc(db, 'clientes',val.id)).then(() => {
              console.log("Document successfully deleted!");
          }).catch((error) => {
              console.error("Error removing document: ", error);
          });
        } catch (error) {
              console.log(error);
        }
      },
      clears () {
          this.name = null;
          this.email = null;
          this.phoneNumber = null;
          this.address = null;
          this.checkbox = null;
          this.credate = null;
          this.dial_reg = false;
          this.$refs.observer.reset();
        },
      },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    computed: {
    ...mapState(['mdcl_title']),
    ...mapState(['sbdu_title']),
    ...mapState(['btn_new']),
    ...mapState(['btn_delete']),
    ...mapState(['btn_exit']),
    }
}
</script>