<template>
  <v-parallax dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
    <v-row class="center" justify="center">
      <v-col cols="12" class="mt-15 text-center">
       <v-container fluid>
        <h1 class="text-h2 font-weight-thin">
          Quantums Data
        </h1>
        <h3 class="subheading">
          Artificial Intelligence for you!!!!
        </h3>
        <h4 class="subheading pt-3">
          Data Analisys and Machine Learning
        </h4>
       </v-container>
      </v-col>
 
      <v-col>
        <v-container fluid>
            <v-row class="align-center" justify="center">
              <v-col cols="12" class="pl-6 py-2">
              <v-card class="align-center" elevation="20" width="650">
                <v-card-title>Ejemplo de una Solución Responsive</v-card-title>
                <v-card-text>Para realizar una prueba de la solución, mediante Autenticación Federada puede usar su cuenta Google o Facebook, 
                o crear una usando Mail verification mediante una cuenta nueva en el sistema.
                <p> </p>
                Podra acceder a los ejemplos y navegar por el menu de las opciones disponibles.
              </v-card-text>
            </v-card>
              </v-col>
            </v-row>
        </v-container>
      </v-col>

      <v-col>
        <p> </p>
        <v-container fluid>
          <v-row class="align-center" justify="center" no-gutters>
            <v-col cols="12">
                <v-toolbar floating color="blue darken-3" height="100">
                  <v-btn color="orange darken-3 white--text" width="350" height="70" href='https://www.quantumsdata.co' title="Quieres saber como se hace">Quieres saber como se hace</v-btn>
                </v-toolbar>
            </v-col>
          </v-row>
        </v-container>

      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
    name: 'QDWellcome',
}
</script>