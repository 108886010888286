<template>
  <v-dialog v-model="dial_reg" max-width="500" persistent>
      <v-card elevation="10" loading>
          <v-card-title class="headline">{{rsps_title}}</v-card-title>
          <v-container>
              <v-card-text v-if="error" class="red darken-2 white--text">{{error}}></v-card-text>
              <v-divider></v-divider>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="resetpass">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-text-field solo type="email" label="E-mail*" prepend-inner-icon="mdi-account" v-model="email" :error-messages="errors" required></v-text-field>
                </validation-provider>
                  <v-btn class="success pd-2 ml-4 xs" type="submit" :disabled="invalid">{{btn_send}}</v-btn>
                  <v-btn class="error pd-2 ml-4 xs" @click.stop="clears" to="/">{{btn_exit}}</v-btn>
                  <br>
              </v-form>
            </validation-observer>
            <v-divider></v-divider>
          </v-container>
          <v-divider></v-divider>
          <v-card-text class="center">{{msg_windsdml}}</v-card-text>
          <v-card-text class="small center">{{msg_windfiob}}</v-card-text>
          <v-divider></v-divider>
      </v-card>
  </v-dialog>       
</template>

<script>
import { mapState } from "vuex"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { required, digits, email} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} debe de ser de {length} digitos. ({_value_})',
  //message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} no puede estar vacio',
  //message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
  message: 'Email debe ser valido',
  //message: 'Email must be valid'
})

export default {
  components: {
      ValidationProvider,
      ValidationObserver,
  },
  data() {
    return {
      dial_reg: true,
      email: null,
      error: null,
    }
  },
  methods: {
    resetpass() {
      const auth = getAuth()
      auth.useDeviceLanguage = 'es';
      sendPasswordResetEmail(auth, this.email).then(function() {
        //this.dial_reg = false;
        //this.$refs.observer.reset();
      })
      .catch((error) => {
        // var errorCode = error.code;
        const errorMessage = error.message;
        this.error = errorMessage;
      });
      this.error = 'El correo fue enviado'
    },
    clears () {
      this.email = null;
      this.dial_reg = false;
      this.error = null,
      this.$refs.observer.reset();
    }
  },
  computed: {
    ...mapState(['rsps_title']),
    ...mapState(['btn_send']),
    ...mapState(['btn_exit']),
    ...mapState(['msg_windfiob']),
    ...mapState(['msg_windsdml']),
  }  
}
</script>