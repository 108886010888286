<template lang="es">
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn class="mr-4" dark color="primary" @click="dialog = true">
            <v-icon small>mdi-plus</v-icon>          
             Agregar
          </v-btn>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Hoy</v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Dia</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 Dias</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>
      </v-sheet>
        <v-sheet height="600">
          <v-calendar ref="calendar" v-model="focus" color="primary" locale="es" :events="events" :event-color="getEventColor"
            :type="type" :short-weekdays="false" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay" @change="updateRange">
            <!--@change="updateRange" -->
          </v-calendar>
            <!-- Dialog -->
            <v-dialog v-model="dialog" width="500" persistent>
              <v-card>
                <v-card-title class="ml-0">Adicionar la informacion de un Evento</v-card-title>
                  <v-container>
                    <v-form @submit.prevent="addEvent">
                      <v-text-field type="text" label="Agregar Nombre" v-model="name"></v-text-field>
                      <v-text-field type="text" label="Detalles" v-model="details"></v-text-field>
                      <v-text-field type="date" label="Inicio del Evento" v-model="start"></v-text-field>
                      <v-text-field type="date" label="Fin del Evento" v-model="end"></v-text-field>
                      <v-radio-group type="text" v-model="color" row class="ml-0">
                        <v-radio v-for="(item, index) in optionsColors" :key="index" :label="item.text" :value="item.value" plain></v-radio>
                      </v-radio-group>
                      <div >Selected: <strong>{{color}}</strong></div>
                      <v-btn class="mr 4" type="submit" @click.stop="dialog = false">Agregar Evento</v-btn>
                    </v-form>
                  </v-container>
                  <v-divider></v-divider>
                <v-card-text>Digite toda la informacion relevante al evento</v-card-text>
              </v-card>
            </v-dialog>
            <!-- Fin Dialog -->

            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon @click="deleteEvent(selectedEvent)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="currentlyEditing !== selectedEvent.id" icon 
                  @click.prevent="editEvent(selectedEvent.id)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                  <v-card-text>
                    <v-form v-if="currentlyEditing !== selectedEvent.id">
                      {{selectedEvent.name}} - {{selectedEvent.details}}
                    </v-form>

                    <v-form v-else>
                      <v-text-field type="text" label="Editar Nombre" v-model="selectedEvent.name"></v-text-field>
                      <textarea-autosize type="text" label="Editar Detalles" v-model="selectedEvent.details" style="width: 100%" :min-height="100"></textarea-autosize>                      
                    </v-form>
                    <!--<span v-html="selectedEvent.details"></span> -->
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">Cancel</v-btn>
                    <v-btn text v-if="currentlyEditing == selectedEvent.id" color="secondary" @click.prevent="updateEvent(selectedEvent)">Guardar Cambios</v-btn>
                  </v-card-actions>
              </v-card>
            </v-menu>
        </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {db} from '../main'
import { collection, query, getDocs, doc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { mapGetters } from "vuex";

export default {
    name: 'Calendario',
    data: () => ({
      start: '',
      end: '',
      focus: '',
      type: 'month',
      typeToLabel: {month: 'Mes', week: 'Semana', day: 'Dia', '4day': '4 Dias',},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      name: null,
      details: null,
      dialog: false,
      currentlyEditing: null,
      color: null,
      //colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      optionsColors: [
                { text: 'Blue', value: 'blue' },
                { text: 'Indigo', value: 'indigo' },
                { text: 'Deep Purple', value: 'deep-purple' },
                { text: 'Cyan', value: 'cyan' },
                { text: 'Green', value: 'green' },
                { text: 'Orange', value: 'orange' },
                { text: 'Grey Darken-1', value: 'grey darken-1' }
            ],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    mounted () {
      this.$refs.calendar.checkChange();
    },
    create(){
      this.getEvents();
    },
    methods: {
        async updateEvent(ev){
          try {
            await updateDoc(doc(db, 'eventos', ev.id), {
            //await db.collection(db, 'eventos').doc(ev.id).update({
              name: ev.name,
              details: ev.details
            })
            this.selectedOpen = false;
            this.currentlyEditing = null;
          } catch (error) {
            console.log(error);
          }
        },
        editEvent(id){
          this.currentlyEditing = id;
        },
        async deleteEvent(ev){
          try {
            await deleteDoc(doc(db, 'eventos',ev.id));
            //await db.collection('eventos').doc(ev.id).delete();
            this.selectedOpen = false;
            this.getEvents();
          } catch (error) {
            console.log(error);
          }
        },
        async addEvent() {
            try {
              if(this.name && this.start && this.end){
                await addDoc(collection(db,'eventos'),{
                  name: this.name,
                  details: this.details,
                  start: this.start,
                  end: this.end,
                  color: this.color
                });
                this.getEvents();
                this.name = null;
                this.details = null;
                this.start = null;
                this.end = null;
                this.color = null;
              }else{
                console.log('Campos Obligatorios');
              }
            } catch (error) {
              console.log(error);
            }
        },
        async getEvents(){
            try {
                const q = query(collection(db, "eventos"));
                const snapshot = await getDocs(q);
                const events = [];
                snapshot.forEach(doc => {
                    //console.log(doc.data());
                    let eventoData = doc.data();
                    eventoData.id = doc.id;
                    events.push(eventoData);
                });

                this.events = events;

            } catch (error) {
                console.log(error);
            }
        },
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange () {
        // updateRange ({ start, end })
        //    const events = []

        //    const min = new Date(`${start.date}T00:00:00`)
        //    const max = new Date(`${end.date}T23:59:59`)
        //    const days = (max.getTime() - min.getTime()) / 86400000
        //    const eventCount = this.rnd(days, days + 20)

        //    for (let i = 0; i < eventCount; i++) {
        //    const allDay = this.rnd(0, 3) === 0
        //    const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //    const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //    const second = new Date(first.getTime() + secondTimestamp)

            //events.push({
            //    name: this.names[this.rnd(0, this.names.length - 1)],
            //    start: first,
            //    end: second,
            //    color: this.colors[this.rnd(0, this.colors.length - 1)],
            //    timed: !allDay,
            //})
            //}

          //this.events = events;
          this.getEvents();
          
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        },
    computed: {
      ...mapGetters({user: "user",})
    },
    }
</script>